import React from 'react'
import Modal from '../shared/modal'

const BackInstructionsModal = ({ onClose, showGeoRestrictionAlert }) => {
  const renderGeoRestrictionAlert = () => (
    <div
      class='rounded-b border-t-4 border-teal-500 bg-teal-100 px-4 py-3 text-teal-900 shadow-md'
      role='alert'
    >
      <div class='flex'>
        <div class='py-1'>
          <svg
            class='mr-4 h-6 w-6 fill-current text-teal-500'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
          >
            <path d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z' />
          </svg>
        </div>
        <div>
          <p class='font-bold'>Geolocation Restriction</p>
          <p class='text-sm'>
            By pressing <b>I understand</b> you confirm that you are not a US
            citizen, or a resident in the US and that you are not accessing this
            website from the United States of America.
          </p>
        </div>
      </div>
    </div>
  )

  return (
    <Modal show={true} size='sm'>
      <div className='flex h-full w-full flex-col items-center justify-center p-4 md:px-10 md:py-10'>
        <div className='mx-auto flex w-full flex-col items-center space-y-4'>
          <div className='w-full text-left text-gray-600'>
            You'll need to connect your wallet before you can back this project.
            The following screens will guide you to connect your wallet. Then,
            you'll be able to back this project.
            <br />
            <br />
            If the project reaches its funding goal, your NFT will be
            transferred to your wallet - if the project does not get fully
            funded, you will be reimbursed.
          </div>
          {showGeoRestrictionAlert && renderGeoRestrictionAlert()}

          <div className='flex w-full flex-col items-center'>
            <button
              className={`
                              cursor-pointer rounded-full bg-blue-600 px-12 py-3 text-sm font-semibold
                              uppercase text-white focus:outline-none
                          `}
              type='button'
              onClick={onClose}
            >
              I understand
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BackInstructionsModal
