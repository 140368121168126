import React, { useImperativeHandle, useRef } from 'react'

import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import ImageTool from '@editorjs/image'
import Embed from '@editorjs/embed'

import { useEffect } from 'react'

const Editor = React.forwardRef(({ type, data }, ref) => {
  const editorRef = useRef()

  useImperativeHandle(ref, () => ({
    saveData() {
      return editorRef?.current.save()
    },
  }))

  useEffect(() => {
    const csrf = document.querySelector("meta[name='csrf-token']")?.content

    const editor = new EditorJS({
      holder: `editorjs-${type}`,
      tools: {
        header: {
          class: Header,
          config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3, 4],
            defaultLevel: 1,
          },
        },
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered',
          },
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: '/project_images',
            },
            additionalRequestHeaders: {
              'X-CSRF-Token': csrf,
            },
            types: 'image/png, image/jpg',
          },
        },
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              vimeo: true,
            },
          },
        },
      },
      data,
    })

    editorRef.current = editor

    return () => {
      editor.destroy()
    }
  }, [data])

  return <div ref={ref} id={`editorjs-${type}`} className='bg-white pt-4' />
})

export default Editor
