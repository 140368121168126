import React, { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup'
import { gql, useQuery } from '@apollo/client'

const RAISED_ICP = 83506
const RAISED_USD = 613877

const CONFIG_QUERY = gql`
  query {
    config {
      successfulProjectCount
      totalRaisedUsd
      totalContributions
    }
  }
`

export default function Hero() {
  const { data, error } = useQuery(CONFIG_QUERY)

  return (
    <section className='w-full'>
      <div className='mx-auto flex w-full max-w-5xl flex-col py-4 pt-60 sm:pt-44'>
        <h1 className='homeHero'>
          Crowdfund the <em>future</em>
          <br />
        </h1>
        <button
          type='button'
          className='my-10 mx-auto inline-flex w-1/2 items-center rounded-full border border-transparent bg-gradient-to-br from-blue-500 to-blue-600  px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-1/3'
        >
          <div className='mx-auto flex'>
            <p className='text- text-center sm:text-xl'>
              <Link to='/projects/new'>Raise in cryptocurrency</Link>
            </p>
          </div>
        </button>
        <div className='flex flex-wrap items-center justify-center pb-2'>
          {/* :BITCOIN 1 */}
          <span className='m-3 inline-block'>
            <svg
              className='h-10 w-10'
              fill='#F7931A'
              xmlns='http://www.w3.org/2000/svg'
              xmlSpace='preserve'
              shapeRendering='geometricPrecision'
              textRendering='geometricPrecision'
              viewBox='0 0 4091.27 4091.73'
            >
              <path d='M4030.06 2540.77c-273.24,1096.01 -1383.32,1763.02 -2479.46,1489.71 -1095.68,-273.24 -1762.69,-1383.39 -1489.33,-2479.31 273.12,-1096.13 1383.2,-1763.19 2479,-1489.95 1096.06,273.24 1763.03,1383.51 1489.76,2479.57l0.02 -0.02z' />
              <path
                fill='white'
                fillRule='nonzero'
                d='M2947.77 1754.38c40.72,-272.26 -166.56,-418.61 -450,-516.24l91.95 -368.8 -224.5 -55.94 -89.51 359.09c-59.02,-14.72 -119.63,-28.59 -179.87,-42.34l90.16 -361.46 -224.36 -55.94 -92 368.68c-48.84,-11.12 -96.81,-22.11 -143.35,-33.69l0.26 -1.16 -309.59 -77.31 -59.72 239.78c0,0 166.56,38.18 163.05,40.53 90.91,22.69 107.35,82.87 104.62,130.57l-104.74 420.15c6.26,1.59 14.38,3.89 23.34,7.49 -7.49,-1.86 -15.46,-3.89 -23.73,-5.87l-146.81 588.57c-11.11,27.62 -39.31,69.07 -102.87,53.33 2.25,3.26 -163.17,-40.72 -163.17,-40.72l-111.46 256.98 292.15 72.83c54.35,13.63 107.61,27.89 160.06,41.3l-92.9 373.03 224.24 55.94 92 -369.07c61.26,16.63 120.71,31.97 178.91,46.43l-91.69 367.33 224.51 55.94 92.89 -372.33c382.82,72.45 670.67,43.24 791.83,-303.02 97.63,-278.78 -4.86,-439.58 -206.26,-544.44 146.69,-33.83 257.18,-130.31 286.64,-329.61l-0.07 -0.05zm-512.93 719.26c-69.38,278.78 -538.76,128.08 -690.94,90.29l123.28 -494.2c152.17,37.99 640.17,113.17 567.67,403.91zm69.43 -723.3c-63.29,253.58 -453.96,124.75 -580.69,93.16l111.77 -448.21c126.73,31.59 534.85,90.55 468.94,355.05l-0.02 0z'
              />
            </svg>
          </span>

          {/* :ETHEREUM 2 */}
          <span className='m-3 inline-block'>
            <svg
              className='h-10 w-10 fill-current'
              xmlns='http://www.w3.org/2000/svg'
              xmlSpace='preserve'
              viewBox='0 0 784.37 1277.39'
            >
              <polygon
                className='fill-current text-gray-600'
                fillRule='nonzero'
                points='392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54 '
              />
              <polygon
                className='fill-current text-gray-400'
                fillRule='nonzero'
                points='392.07,0 -0,650.54 392.07,882.29 392.07,472.33 '
              />
              <polygon
                className='fill-current text-gray-700'
                fillRule='nonzero'
                points='392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89 '
              />
              <polygon
                className='fill-current text-gray-400'
                fillRule='nonzero'
                points='392.07,1277.38 392.07,956.52 -0,724.89 '
              />
              <polygon
                className='fill-current text-gray-900'
                fillRule='nonzero'
                points='392.07,882.29 784.13,650.54 392.07,472.33 '
              />
              <polygon
                className='fill-current text-gray-700'
                fillRule='nonzero'
                points='0,650.54 392.07,882.29 392.07,472.33 '
              />
            </svg>
          </span>

          {/* :ICP 1 */}

          <img src='/icp.svg' className='m-3 inline-block h-10 w-10' />

          {/* :POLYGON 1 */}
          <span className='m-3 inline-block'>
            <svg
              className='h-10 w-10'
              fill='#8247E5'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              viewBox='0 0 38.4 33.5'
            >
              <path
                class='st0'
                d='M29,10.2c-0.7-0.4-1.6-0.4-2.4,0L21,13.5l-3.8,2.1l-5.5,3.3c-0.7,0.4-1.6,0.4-2.4,0L5,16.3   c-0.7-0.4-1.2-1.2-1.2-2.1v-5c0-0.8,0.4-1.6,1.2-2.1l4.3-2.5c0.7-0.4,1.6-0.4,2.4,0L16,7.2c0.7,0.4,1.2,1.2,1.2,2.1v3.3l3.8-2.2V7   c0-0.8-0.4-1.6-1.2-2.1l-8-4.7c-0.7-0.4-1.6-0.4-2.4,0L1.2,5C0.4,5.4,0,6.2,0,7v9.4c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7   c0.7,0.4,1.6,0.4,2.4,0l5.5-3.2l3.8-2.2l5.5-3.2c0.7-0.4,1.6-0.4,2.4,0l4.3,2.5c0.7,0.4,1.2,1.2,1.2,2.1v5c0,0.8-0.4,1.6-1.2,2.1   L29,28.8c-0.7,0.4-1.6,0.4-2.4,0l-4.3-2.5c-0.7-0.4-1.2-1.2-1.2-2.1V21l-3.8,2.2v3.3c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7   c0.7,0.4,1.6,0.4,2.4,0l8.1-4.7c0.7-0.4,1.2-1.2,1.2-2.1V17c0-0.8-0.4-1.6-1.2-2.1L29,10.2z'
              />
            </svg>
          </span>

          {/* :ckBTC 1 */}

          <img src='/ckbtc.svg' className='m-3 inline-block h-10 w-10' />
        </div>
        <div className='bg-clear mt-2 pb-2'>
          <div className='relative'>
            <div className='bg-clear absolute inset-0' />
            <div className='relative mx-auto max-w-7xl px-8 sm:px-6 lg:px-8'>
              <div className='mx-auto max-w-4xl'>
                <dl className='space-y-4 rounded-lg sm:grid sm:grid-cols-3'>
                  <div className='flex flex-col border-b border-gray-100 p-2 text-center sm:mt-4 sm:border-0 sm:border-r'>
                    <dt className='text-md font-regular  order-2 mt-2 leading-6 text-neutral-500'>
                      Projects Fully Funded
                    </dt>
                    <dd className='order-1 flex items-center justify-center text-4xl font-bold tracking-tight text-blue-600'>
                      <CountUp end={data?.config?.successfulProjectCount} />
                    </dd>
                  </div>
                  <div className='flex flex-col border-t border-b border-gray-100 p-2 text-center sm:border-0 sm:border-l sm:border-r'>
                    <dt className='text-md font-regular order-2 mt-2 leading-6 text-neutral-500'>
                      Total Raised
                    </dt>
                    <dd className='order-1 flex items-center justify-center text-4xl font-bold tracking-tight text-blue-600'>
                      <img className='mr-2 inline-block h-4' />
                      <span>
                        <CountUp
                          end={data?.config?.totalRaisedUsd}
                          prefix='$ '
                          suffix=''
                        />
                      </span>
                    </dd>
                  </div>
                  <div className='flex flex-col border-t border-gray-100 p-2 text-center sm:border-0 sm:border-l'>
                    <dt className='text-md font-regular order-2 mt-2 leading-6 text-neutral-500'>
                      Contributions Made
                    </dt>
                    <dd className='order-1 flex items-center justify-center text-4xl font-bold tracking-tight text-blue-600'>
                      <CountUp end={data?.config?.totalContributions} />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
