import React from 'react'

import classNames from 'classnames'
import { Link } from 'react-router-dom'

export default function Item({ item, isLoading = false }) {
  if (isLoading === true) {
    return (
      <a className='group flex w-full cursor-pointer flex-col'>
        <figure
          className={classNames(
            'h-32 w-full animate-pulse overflow-hidden lg:rounded-lg',
            'bg-gray-200',
          )}
        />
        <div className='mt-2 flex w-full flex-row justify-between text-sm'>
          <div className='h-5 w-32 animate-pulse rounded-lg bg-gray-200' />
          <div className='h-5 w-10 animate-pulse rounded-lg bg-gray-200' />
        </div>
        <div className='my-1 h-3 w-20 animate-pulse rounded-lg bg-gray-200' />
        <div className='my-1 h-3 w-20 animate-pulse rounded-lg bg-gray-200' />
      </a>
    )
  }

  return (
    <Link to={`/projects/${item.urlSlug}`}>
      <div
        className='
          group flex w-full transform 
          cursor-pointer flex-col rounded-lg 
          shadow-lg transition duration-300 ease-in-out
          hover:-translate-y-1 hover:shadow-xl
        '
      >
        <figure
          className={classNames(
            'h-44 w-full overflow-hidden rounded-t-lg',
            'bg-blue-100',
          )}
        >
          <img src={item.listImage} className='h-full w-full object-cover' />
        </figure>
        <div className='px-4 py-2'>
          <p className='font-semibold'>{item.title}</p>
          <p className='text-gray-700'>
            <img
              src={`/${item.fundingType}.svg`}
              className='inline-block w-3'
            />{' '}
            {item.totalGoal.toPrecision(6)} goal
          </p>
          <p className='text-sm font-semibold text-blue-600'>
            <StatusText status={item.aasmState} />
          </p>
          <p className='text-sm text-gray-400'>
            by {`${item.user?.firstName} ${item.user?.lastName}`}
          </p>
        </div>
      </div>
    </Link>
  )
}

const StatusText = (props) => {
  switch (props.status) {
    case 'submitted':
      return 'Not yet approved'
    case 'approved':
      return 'Going live soon'
    case 'whitelist':
      return 'Open to whitelist'
    case 'live':
      return 'Live Now'
    case 'fully_funded':
      return 'Fully Funded!'
    case 'archived':
      return 'Archived'
    default:
      return '- '
  }
}
