import React from 'react'

const fontMapper = {
  1: 'text-3xl',
  2: 'text-2xl',
  3: 'text-xl',
  4: 'text-lg',
}

const linkStyle =
  '[&>a]underline-offset-8 [&>a]:font-bold [&>a]:text-blue-600 [&>a]:underline'

const Description = ({ blocks }) => {
  const renderParagraph = (block) => (
    <div
      className={linkStyle}
      key={block.id}
      dangerouslySetInnerHTML={{ __html: block.data.text }}
    />
  )

  const renderList = (block) => {
    const list =
      block.data.style === 'unordered'
        ? renderUnorderedList(block)
        : renderOrderedList(block)

    return <div className='ml-4'>{list}</div>
  }

  const renderListItem = (item) => (
    <li
      dangerouslySetInnerHTML={{ __html: item }}
      className={`${linkStyle} mt-1`}
    />
  )

  const renderUnorderedList = (block) => (
    <ul key={block.id} className='list-disc'>
      {block.data.items.map(renderListItem)}
    </ul>
  )

  const renderOrderedList = (block) => (
    <ol key={block.id} className='list-decimal'>
      {block.data.items.map(renderListItem)}
    </ol>
  )

  const renderImage = (block) => {
    const { file, caption, stretched, withBorder, withBackground } = block.data

    if (withBackground) {
      return (
        <div className='mt-5 flex h-80 justify-center rounded bg-gray-100 p-3'>
          <img src={file.url} />
        </div>
      )
    }

    return <img src={file.url} />
  }

  const renderEmbed = (block) => {
    const { embed, height, width, service } = block.data

    return (
      <div className='flex justify-center'>
        <iframe width={width} height={height} src={embed} />
      </div>
    )
  }

  const renderHeader = (block) => {
    const { level, text } = block.data

    return <div className={`${fontMapper[level]} font-bold`}>{text}</div>
  }

  const getBlock = (block) => {
    switch (block.type) {
      case 'paragraph':
        return renderParagraph(block)
      case 'list':
        return renderList(block)
      case 'image':
        return renderImage(block)
      case 'embed':
        return renderEmbed(block)
      case 'header':
        return renderHeader(block)
      default:
        break
    }
  }

  const renderBlock = (block) => <div className='mt-4'>{getBlock(block)}</div>

  return <div>{blocks.blocks.map(renderBlock)}</div>
}

export default Description
