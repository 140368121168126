import React, { useCallback, useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import useSession from '../hooks/useSession'
import { Link, useNavigate } from 'react-router-dom'
import { AuthClient } from '@dfinity/auth-client'
import { Principal } from '@dfinity/principal'
import useUser from '../hooks/useUser'
import { createActor } from '../helpers/createActor'
import authenticatorDid from '../idls/authenticator.did'
import { Spinner } from '../components/shared/loading-spinner'
import { wallets } from '../helpers/wallets'
import CookiesBanner from '../components/shared/cookiesBanner'

const REQUEST_NONCE = gql`
  mutation GetMetamaskNonce($metamaskAddress: String!) {
    getMetamaskNonce(input: { metamaskAddress: $metamaskAddress }) {
      nonce
    }
  }
`

const REQUEST_IC_NONCE = gql`
  mutation GetICNonce($icPrincipal: String!, $provider: String!) {
    getIcNonce(input: { icPrincipal: $icPrincipal, provider: $provider }) {
      nonce
    }
  }
`

const NFID_APPLICATION_NAME = 'Funded'
const NFID_APPLICATION_LOGO_URL =
  'https%3A%2F%2Ffunded-cdn.s3.amazonaws.com%2Femail-logo.png'

const AUTH_CANISTER_ID = 'c5ons-baaaa-aaaap-aaz6q-cai'

const Login = () => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [loginError, setLoginError] = useState()

  const [metamaskAddress, setMetamaskAddress] = useState()
  const [requestNonce, { data: nonceData }] = useMutation(REQUEST_NONCE)
  const [requestIcNonce, { data: icNonceData }] = useMutation(REQUEST_IC_NONCE)
  const [signedMessage, setSignedMessage] = useState()

  const [metamaskLoading, setMetamaskLoading] = useState(false)
  const [internetIdentityLoading, setInternetIdentityLoading] = useState(false)
  const [nfidLoading, setNfidLoading] = useState(false)
  const [plugLoading, setPlugLoading] = useState(false)

  const [icIdentity, setIcIdentity] = useState()

  const { setSession } = useSession()
  const { user } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) navigate('/', { replace: true })
  }, [user])

  useEffect(() => {
    if (!metamaskAddress) return

    requestNonce({ variables: { metamaskAddress } })
  }, [metamaskAddress])

  const authenticateWithPlug = async () => {
    const principal = await wallets['plug'].connect([AUTH_CANISTER_ID])
    const authActor = await window.ic.plug.createActor({
      canisterId: AUTH_CANISTER_ID,
      interfaceFactory: authenticatorDid,
    })

    return { principal, authActor, provider: 'plug' }
  }

  const getICIdentity = async (provider) => {
    const authClient = await AuthClient.create()

    if (provider === 'plug') return authenticateWithPlug()

    const identityProvider =
      provider === 'nfid'
        ? `https://nfid.one/authenticate/?applicationName=${NFID_APPLICATION_NAME}&applicationLogo=${NFID_APPLICATION_LOGO_URL}#authorize`
        : ''

    return new Promise((resolve, reject) => {
      authClient.login({
        identityProvider,
        derivationOrigin:
          window.location.origin === 'https://funded.app'
            ? 'https://kn5ky-6iaaa-aaaai-qbikq-cai.ic0.app'
            : null,
        onSuccess: async () => {
          const identity = authClient.getIdentity()
          const authActor = createActor(AUTH_CANISTER_ID, authenticatorDid, {
            agentOptions: { identity },
          })

          if (!identity) {
            return reject(new Error('identity is null'))
          }

          resolve({ identity, provider, authActor })
        },
        onError: reject,
      })
    })
  }

  useEffect(() => {
    if (!icIdentity) return

    const { identity, provider, principal } = icIdentity

    requestIcNonce({
      variables: {
        icPrincipal: principal || identity.getPrincipal().toText(),
        provider,
      },
    })
  }, [icIdentity])

  useEffect(() => {
    if (!icNonceData || !icIdentity) return

    const { principal, identity, provider } = icIdentity

    icIdentity.authActor
      .setNonce(icNonceData.getIcNonce.nonce)
      .then(() => {
        return performAuth({
          ic_principal: principal || identity.getPrincipal().toText(),
          ic_provider: provider,
        })
      })
      .then(setSession)
      .catch(console.log)
      .finally(() => {
        setInternetIdentityLoading(false)
        setNfidLoading(false)
      })
  }, [icNonceData, icIdentity])

  const internetIdentityAuth = () => {
    setInternetIdentityLoading(true)
    getICIdentity('internetIdentity').then(setIcIdentity).catch(console.log)
  }

  const nfidAuth = () => {
    setNfidLoading(true)
    getICIdentity('nfid').then(setIcIdentity).catch(console.log)
  }

  const plugAuth = () => {
    setPlugLoading(true)
    getICIdentity('plug').then(setIcIdentity).catch(console.log)
  }

  useEffect(() => {
    const nonce = nonceData?.getMetamaskNonce?.nonce
    if (!nonce) return

    const signingMessage = `Nonce:${nonce}`
    const msg = `0x${Buffer.from(signingMessage, 'utf8').toString('hex')}`

    ethereum
      .request({
        method: 'personal_sign',
        params: [msg, metamaskAddress],
      })
      .then(setSignedMessage)
      .catch((e) => {
        console.log(e)
        setLoginError(e)
        setMetamaskAddress()
        setMetamaskLoading(false)
      })
  }, [nonceData, metamaskAddress])

  useEffect(() => {
    if (!metamaskAddress || !signedMessage) return

    getToken(signedMessage)
      .then(setSession)
      .catch((e) => {
        console.log(e)
        setLoginError(e)
        setMetamaskAddress()
      })
  }, [metamaskAddress, signedMessage])

  const metamaskAuth = async () => {
    setMetamaskLoading(true)
    const accounts = await window.ethereum?.request({
      method: 'eth_requestAccounts',
    })
    setMetamaskAddress(accounts[0])
  }

  const getToken = useCallback(
    (signature) => {
      return performAuth({ metamask_address: metamaskAddress, signature })
    },
    [metamaskAddress],
  )

  const performAuth = useCallback(async (params) => {
    const body = {
      grant_type: 'password',
      client_id: 'rcRKsb5lC7JLh3gWHnYO6n9ypxv2EWmGCKWrTEi7Hp4',
      client_secret: 'WjzjyqudlmCdTsxsaZdJpIlrz21laSHL5O8qJq9US-E',
      ...params,
    }

    const tokenResponse = await fetch(`/oauth/token`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!tokenResponse.ok) {
      setLoginError('Invalid login credentials')
      return
    }

    const json = await tokenResponse.json()
    return json
  }, [])

  const login = useCallback(
    (event) => {
      event.preventDefault()
      performAuth({ email, password })
        .then(setSession)
        .catch((e) => {
          console.log(e)
          setLoginError(e)
        })
    },
    [email, password],
  )

  const renderError = () => {
    if (!loginError) return

    return (
      <div
        className='relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700'
        role='alert'
      >
        <span className='block sm:inline'>{loginError}</span>
      </div>
    )
  }

  const icProviders = [
    {
      name: 'Internet Identity',
      action: internetIdentityAuth,
      loading: internetIdentityLoading,
      icon: '/icp.svg',
      iconWidth: 30,
    },
    {
      name: 'NFID',
      action: nfidAuth,
      loading: nfidLoading,
      icon: '/nfid.png',
      iconWidth: 50,
    },
    {
      name: 'Plug',
      action: plugAuth,
      loading: plugLoading,
      icon: '/plug.png',
      iconWidth: 20,
    },
  ]

  return (
    <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <Link to='/'>
          <img src='/funded-logo.svg' className='mx-auto h-12 w-auto' />
        </Link>
        <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
          {' '}
          Sign in to your account
        </h2>
      </div>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          {renderError()}
          <form className='mt-8 space-y-6' onSubmit={login}>
            <div className='field'>
              <label htmlFor='user_email'>Email</label>
              <br />
              <input
                autoFocus='autofocus'
                autoComplete='email'
                className='-indblock focus:borderigo-500 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-indigo-500 sm:text-sm'
                type='email'
                value={email}
                id='user_email'
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className='field'>
              <label htmlFor='user_password'>Password</label>
              <br />
              <input
                autoComplete='current-password'
                className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                type='password'
                id='user_password'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <div className='actions'>
              <input
                type='submit'
                name='commit'
                value='Log in'
                className='flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              />
            </div>
          </form>
          <div className='mt-3'>
            <Link to='/sign-up'>
              <div className='font-medium text-indigo-600 hover:text-indigo-500'>
                Sign up
              </div>
            </Link>
            <Link to='/reset-password'>
              <div className='font-medium text-indigo-600 hover:text-indigo-500'>
                Forgot password
              </div>
            </Link>
          </div>

          <div className='mt-3'>
            <div className='relative'>
              <div className='relative mb-3 flex justify-center text-sm'>
                <span className='bg-white px-2 text-gray-500'>
                  Or continue with
                </span>
              </div>
              <div className='inset-0 flex items-center'>
                <div className='w-full border-t border-gray-300' />
              </div>

              <button
                onClick={metamaskAuth}
                disabled={metamaskLoading}
                className='mt-5 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2'
              >
                {metamaskLoading ? (
                  <span className='h-5 w-5'>
                    <Spinner show={true} />
                  </span>
                ) : (
                  <>
                    <img
                      src='/metamask.svg'
                      className='mr-2'
                      style={{ widht: 30, height: 30 }}
                    />
                    <span>Connect with MetaMask</span>
                  </>
                )}
              </button>
              {icProviders.map((provider) => (
                <button
                  onClick={provider.action}
                  disabled={provider.loading}
                  className='mt-5 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-500 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2'
                >
                  {provider.loading ? (
                    <span className='h-5 w-5'>
                      <Spinner show={true} />
                    </span>
                  ) : (
                    <>
                      <img
                        src={provider.icon}
                        className='mr-2'
                        style={{ width: provider.iconWidth }}
                      />
                      <span>{`Connect with ${provider.name}`}</span>
                    </>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <CookiesBanner />
    </div>
  )
}

export default Login
