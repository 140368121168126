import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useCallback, useEffect, useRef } from 'react'
import Editor from '../components/forms/editor'
import { useNavigate, useParams } from 'react-router-dom'

const PROJECTS_QUERY = gql`
  query Projects($urlSlug: String) {
    project(urlSlug: $urlSlug) {
      id
      title
      storyBlocks
      rewardBlocks
    }
  }
`

const PROJECT_UPDATE_MUTATION = gql`
  mutation CreateProject(
    $project_id: ID!
    $storyBlocks: JSON!
    $rewardBlocks: JSON!
  ) {
    updateProject(
      input: {
        projectId: $project_id
        storyBlocks: $storyBlocks
        rewardBlocks: $rewardBlocks
      }
    ) {
      project {
        id
      }
    }
  }
`

const EditProject = () => {
  const { urlSlug } = useParams()

  const storyEditor = useRef()
  const rewardsEditor = useRef()

  const navigate = useNavigate()

  const {
    data: project,
    loading,
    error,
  } = useQuery(PROJECTS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { urlSlug },
  })

  const [updateProject, { data: updatedProject }] = useMutation(
    PROJECT_UPDATE_MUTATION,
  )

  console.log('UPDATED', updatedProject)

  useEffect(() => {
    if (!updatedProject) return

    navigate(`/projects/${urlSlug}`)
  }, [updatedProject, urlSlug])

  const update = useCallback(async () => {
    const story = await storyEditor.current.saveData()
    const rewards = await rewardsEditor.current.saveData()

    await updateProject({
      variables: {
        project_id: project.project.id,
        storyBlocks: story,
        rewardBlocks: rewards,
      },
    })
  }, [project])

  const doUpdate = () => {
    update().then(console.log).catch(console.log)
  }

  return (
    <div className='container mx-auto mt-20'>
      <h3>Story</h3>
      <div className='mt-5 rounded-md bg-gray-200 p-3'>
        <Editor
          ref={storyEditor}
          type='story'
          data={project?.project?.storyBlocks}
        />
      </div>
      <h3>Rewards</h3>
      <div className='mt-5 rounded-md bg-gray-200 p-3'>
        <Editor
          ref={rewardsEditor}
          type='rewards'
          data={project?.project?.rewardBlocks}
        />
      </div>
      <button
        className='justify-center rounded-xl bg-blue-600 py-3 
                            px-4 text-base font-medium'
        onClick={doUpdate}
      >
        UPDATE
      </button>
    </div>
  )
}

export default EditProject
