import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import Hero from '../components/project/hero'
import TabBar from '../components/project/tabBar'
import Faqs from '../components/project/faqs'
import CampaignDetails from '../components/project/details'
import NFTCollection from '../components/project/nftCollection'
import Activity from '../components/project/activity'

import './Project.css'

const PROJECTS_QUERY = gql`
  query Projects($urlSlug: String) {
    project(urlSlug: $urlSlug) {
      id
      title
      fundingType
      coverImage
      video
      aasmState
      discordLink
      twitterLink
      webLink
      tiers
      user {
        firstName
        lastName
        bio
        profilePicture
      }
      whitelistAt
      liveAt
      endAt
      externalId
      totalGoal
      story
      rewards
      storyBlocks
      rewardBlocks
      oversellPercentage
      raisedAmount
      escrowAddress
      blockchain
      exampleNfts {
        id
        asset
      }
      projectTeamMembers {
        id
        fullName
        image
        role
      }
      geoRestriction
    }
  }
`

const Project = () => {
  const [selectedTab, setTab] = useState('campaign-details')
  const { urlSlug } = useParams()

  const {
    data: project,
    loading,
    error,
  } = useQuery(PROJECTS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { urlSlug },
  })

  return (
    <>
      <div className='w-full bg-gray-50'>
        {project && <Hero project={project.project} />}
        {project && <TabBar selected={selectedTab} onSelect={setTab} />}
        {project && selectedTab === 'campaign-details' && (
          <CampaignDetails project={project.project} />
        )}
        {selectedTab === 'faqs' && <Faqs />}
        {project && selectedTab === 'nft-collection' && (
          <NFTCollection project={project.project} />
        )}
        {selectedTab === 'activity' && <Activity project={project.project} />}
      </div>
    </>
  )
}

export default Project
