import React, { useEffect } from 'react'
import {
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import Footer from '../components/shared/footer'
import Navbar from '../components/shared/navbar'
import CookiesBanner from '../components/shared/cookiesBanner'
import useUser from '../hooks/useUser'
import About from './about'
import Blog from './blog'
import FundSuccess from './fundSuccess'
import Home from './home'
import Login from './login'
import NewProject from './newProject'
import Page from './page'
import Project from './project'
import Projects from './projects'
import Register from './register'
import SinglePost from './singlePost'
import NftReward from './nftReward'
import MintSuccess from './mintSuccess'
import EditProject from './editProject'

const MainLayout = ({ requireAuth }) => {
  const { pathname } = useLocation()
  const { user, userLoading } = useUser()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (requireAuth && !user && !userLoading) return <Login />

  return (
    <div className='w-full'>
      <div className='min-h-screen'>
        <Navbar />
        <Outlet />
      </div>
      <Footer />
      <CookiesBanner />
    </div>
  )
}

const App = () => {
  return (
    <Routes>
      <Route element={<MainLayout requireAuth />}>
        <Route path='/projects/new' element={<NewProject />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/projects/:urlSlug' element={<Project />} />
        <Route path='/projects/:urlSlug/edit' element={<EditProject />} />
        <Route path='/projects/:urlSlug/success' element={<FundSuccess />} />
        <Route path='/pages/:urlSlug' element={<Page />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog/:urlSlug' element={<SinglePost />} />
        <Route path='/nft-reward' element={<NftReward />} />
        <Route path='/nft-reward/success' element={<MintSuccess />} />
      </Route>
      <Route path='/login' element={<Login />} />
      <Route path='/sign-up' element={<Register />} />
    </Routes>
  )
}

export default App
