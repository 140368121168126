import React from 'react'
import Menu from './menu'
import Rewards from './rewards'
import AuthorProfile from './authorProfile'
import Description from '../description'

const CampaignDetails = ({ project }) => {
  const renderTeamMembers = () => {
    if (project.projectTeamMembers.length < 1) return

    return (
      <div>
        <p className='mb-4 text-lg font-bold text-black'>Team Members</p>
        {project.projectTeamMembers.map(
          ({ fullName, image, description, role }) => (
            <AuthorProfile
              author={{
                firstName: fullName,
                profilePicture: image,
                bio: description,
                role,
              }}
            />
          ),
        )}
      </div>
    )
  }

  const renderStory = () => {
    if (project.storyBlocks) return <Description blocks={project.storyBlocks} />

    return (
      <div
        className='w description my-2 whitespace-pre-wrap text-gray-600'
        dangerouslySetInnerHTML={{ __html: project.story }}
      />
    )
  }

  return (
    <div className='w-full'>
      <div className='mx-auto flex w-full max-w-7xl flex-col space-y-4 px-4 py-4 md:flex-row md:space-y-0 md:space-x-4'>
        <div className='flex w-full flex-col space-y-8 md:w-3/12'>
          <Menu />
          <p className='text-lg font-bold text-black'>Project Creator</p>
          <AuthorProfile author={project.user} />
          {renderTeamMembers()}
        </div>

        <div className='flex w-full flex-col space-y-8 md:w-9/12'>
          <div className='flex w-full flex-col items-start rounded-2xl bg-white p-8 shadow'>
            <p className='text-2xl font-bold text-black'>Project Overview</p>
            {renderStory()}
            {project.video && (
              <video controls>
                <source src={project.video} />
              </video>
            )}
          </div>
          <Rewards project={project} />
        </div>
      </div>
    </div>
  )
}

export default CampaignDetails
