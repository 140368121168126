import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Tiers from '../home/hero/tiers'
import { intervalToDuration, parseISO } from 'date-fns'
import useICEscrow from '../../hooks/useICEscrow'
import BackInstructionsModal from './backInstructionsModal'
import { Spinner } from '../shared/loading-spinner'
import { Link, useNavigate } from 'react-router-dom'
import './hero.css'
import useETHEscrow from '../../hooks/useETHEscrow'

const Hero = ({
  project: {
    id,
    title,
    aasmState,
    coverImage,
    fundingType,
    twitterLink,
    discordLink,
    webLink,
    tiers,
    whitelistAt,
    endAt,
    liveAt,
    externalId,
    totalGoal,
    oversellPercentage,
    raisedAmount,
    escrowAddress,
    blockchain,
    geoRestriction,
  },
}) => {
  const selectedTierState = useState(0)
  const [showInstructions, setShowInstructions] = useState(false)
  const [seenInstructions, setSeenInstructions] = useState(false)
  const [now, setNow] = useState(Date.now())
  const [loading, setLoading] = useState(false)
  const { stats: icStats, backProject: backICProject } = useICEscrow(externalId)
  const { stats: ethStats, backProject: backETHProject } = useETHEscrow(
    escrowAddress,
    blockchain,
    fundingType,
  )
  const navigate = useNavigate()

  useEffect(() => {
    setInterval(() => setNow(Date.now()), 1000)
  }, [])

  const totalSold = useMemo(() => {
    if (aasmState === 'fully_funded') return raisedAmount || totalGoal
    if (!icStats && !ethStats) return 0

    if (ethStats) {
      return tiers.reduce((acc, tier, index) => {
        return acc + ethStats[index].sold * tier.price
      }, 0)
    }

    return (
      icStats.reduce((acc, stat) => {
        const { priceE8S, priceSatoshi, sold } = stat
        return acc + sold * (priceE8S || priceSatoshi)
      }, 0) / 100_000_000
    )
  }, [icStats, ethStats, fundingType, tiers])

  const showTiers = useMemo(() => {
    return ['submitted', 'approved', 'live', 'whitelist'].includes(aasmState)
  }, [aasmState])

  const backProjectButtonClick = useCallback(() => {
    if (!['whitelist', 'live'].includes(aasmState))
      return alert('This project is not yet live')

    if (!seenInstructions) {
      return setShowInstructions(true)
    }

    if (seenInstructions) return backProject()
  }, [aasmState, fundingType, seenInstructions])

  const backProject = () => {
    setLoading(true)

    const backPromise = ['eth', 'matic'].includes(fundingType)
      ? backETHProject(selectedTierState[0], tiers[selectedTierState[0]].price)
      : backICProject(selectedTierState[0])

    backPromise
      .then(() => {
        window?.plausible?.('FundProject', { props: { projectTitle: title } })
        navigate('success', { replace: true })
      })
      .catch((error) => {
        if (error.code === 'NETWORK_ERROR') {
          return alert(
            `Please change your network to ${error.network.name}. Currently detected ${error.detectedNetwork.name}`,
          )
        }

        if (error.data?.message) {
          return alert(error.data?.message)
        }

        if (error.reason) {
          return alert(error.reason)
        }

        return alert(error)
      })
      .finally(() => setLoading(false))
  }

  const linkWithProtocol = (link) =>
    link.includes('http') ? link : `https://${link}`

  const renderSocialIcons = () => (
    <div className='mb-2 flex w-full flex-row items-center space-x-8'>
      <div className='flex w-6/12 flex-row justify-start space-x-1'>
        {twitterLink && (
          <a
            className={`
                                    cursor-pointer py-2 px-2 text-sm text-gray-900 duration-200 hover:scale-105
                                    hover:text-blue-600
                                `}
            href={linkWithProtocol(twitterLink)}
            target='_blank'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              width='20'
              height='20'
              viewBox='0 0 24 24'
            >
              <path d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z' />
            </svg>
          </a>
        )}
        {discordLink && (
          <a
            href={linkWithProtocol(discordLink)}
            target='_blank'
            className={`
                cursor-pointer py-2 px-2 text-sm text-gray-900 duration-200 hover:scale-105
                hover:text-blue-600
            `}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              width='24'
              height='24'
              viewBox='0 0 71 55'
            >
              <path d='M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z' />
            </svg>
          </a>
        )}
        {webLink && (
          <a
            href={linkWithProtocol(webLink)}
            target='_blank'
            className={`
                cursor-pointer py-2 px-2 text-sm text-gray-900 duration-200 hover:scale-105
                hover:text-blue-600
            `}
          >
            <svg
              fill='currentColor'
              height='20'
              viewBox='0 0 20 20'
              width='20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='m10 0c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm4.2093 14.424c.25-1.1353.4014-2.4007.4394-3.7573h3.9906c-.1286 1.6766-.7366 3.2193-1.6866 4.494-.8994-.3074-1.816-.5527-2.7434-.7367zm-11.16197.7367c-.95066-1.2747-1.558-2.8174-1.68666-4.494h3.99066c.03867 1.3566.19 2.622.43934 3.7573-.92734.184-1.844.4293-2.744.736zm2.74334-9.5807c-.24934 1.134-.40067 2.398-.43934 3.75333h-3.99066c.12866-1.67666.736-3.21933 1.68666-4.49333.90067.308 1.816.55467 2.74334.74zm1.31533.22533c.95533.12934 1.92133.19467 2.894.19467.9727 0 1.9387-.06533 2.894-.19467.234 1.03934.3853 2.22867.4253 3.528h-6.63797c.04-1.29933.19067-2.48866.42467-3.528zm-.42533 4.86137h6.63803c-.04 1.302-.192 2.4926-.426 3.5333-1.918-.2587-3.8667-.2587-5.78537 0-.23466-1.0407-.38666-2.232-.426-3.5333zm7.96733-1.33337c-.0387-1.35533-.19-2.61933-.4393-3.75333.9273-.18533 1.8433-.432 2.7433-.74067.9507 1.27467 1.558 2.81734 1.6867 4.494zm1.3393-5.58066c-.6966.216-1.4026.39333-2.1153.532-.31-.98334-.6993-1.832-1.154-2.508 1.2373.41 2.3487 1.09466 3.2693 1.976zm-5.98797-2.41934c.90867 0 1.87537 1.18667 2.53267 3.16667-1.6807.21133-3.384.21133-5.06533 0 .65733-1.97933 1.624-3.16667 2.53266-3.16667zm-3.872 2.95067c-.71266-.13867-1.41866-.316-2.11466-.532.92066-.88133 2.032-1.566 3.26866-1.976-.45466.67533-.844 1.52533-1.154 2.508zm-2.11466 11.964c.696-.216 1.402-.3927 2.114-.5313.30933.9826.69866 1.8313 1.15266 2.5066-1.236-.41-2.346-1.0946-3.26666-1.9753zm5.98733 2.4187c-.90867 0-1.87533-1.1867-2.53267-3.166 1.68134-.2114 3.38467-.2114 5.06537 0-.6574 1.9793-1.624 3.166-2.5327 3.166zm3.8733-2.9507c.7127.1387 1.4187.3153 2.1147.5313-.9207.8807-2.0313 1.5654-3.2673 1.9754.454-.6754.8433-1.524 1.1526-2.5067z'
                fill='currentColor'
              />
            </svg>
          </a>
        )}
      </div>
    </div>
  )

  const getFormattedInterval = (end) => {
    const endDate = parseISO(end)
    const { hours, minutes, seconds, days } = intervalToDuration({
      start: now,
      end: endDate,
    })

    return `${days > 0 ? `${days}d ` : ''}${hours}h ${minutes}m ${seconds}s`
  }

  const renderStatus = () => {
    switch (aasmState) {
      case 'approved':
        if (whitelistAt)
          return `Whitelist opens in ${getFormattedInterval(whitelistAt)}`
      case 'whitelist':
        return `Public sale in ${getFormattedInterval(liveAt)}`
      case 'fully_funded':
        return 'This project is fully funded'
      case 'closed':
        return 'This project did not reach funding goal'
      default:
        ''
    }
  }

  const renderClaimNftButton = () => {
    return (
      <Link
        to='/nft-reward'
        className={`
            mt-3 flex w-full cursor-pointer appearance-none flex-row justify-center rounded-xl bg-gradient-to-b 
            from-neutral-800 to-black py-5 px-4 text-lg font-medium 
            text-white hover:bg-blue-700 focus:outline-none
        `}
      >
        Claim your NFT
      </Link>
    )
  }

  const renderTwitterShare = () => (
    <div className='w-full py-5'>
      <button
        className={`
                        flex w-full appearance-none  flex-row justify-center rounded-lg 
                        bg-blue-50 py-5 px-4 text-lg font-medium text-blue-700 
                        hover:bg-white focus:outline-none
                        `}
        type='button'
        onClick={handleShare}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='currentColor'
          width='30'
          height='30'
          viewBox='0 0 24 24'
        >
          <path d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z' />
        </svg>
        <p className='ml-4'>Share on Twitter</p>
      </button>
    </div>
  )

  const renderFundButton = () => {
    if (!['whitelist', 'live'].includes(aasmState)) return
    return (
      <button
        className={`
                    mt-3 flex w-full cursor-pointer appearance-none flex-row justify-center rounded-xl bg-gradient-to-b 
                    from-neutral-800 to-black py-5 px-4 text-lg font-medium 
                    text-white hover:bg-blue-700 focus:outline-none
                `}
        type='button'
        onClick={backProjectButtonClick}
      >
        {loading ? (
          <span className='h-5 w-5'>
            <Spinner show={true} />
          </span>
        ) : (
          <>Fund this project</>
        )}
      </button>
    )
  }

  const handleShare = () => {
    if (!window) return

    const message = encodeURI(`${title} on @funded_app`)
    const url = encodeURI(window.location)

    window.location = `https://twitter.com/intent/tweet?text=${message}&url=${url}`
  }

  const renderTimer = () => {
    if (endAt && ['whitelist', 'live'].includes(aasmState)) {
      return getFormattedInterval(endAt)
    }

    return '- --:--:--'
  }

  const renderProgressBar = () => {
    const oversellAvailable = oversellPercentage > 0

    let goalPercentageTotal = 0
    let oversellPercentagePledged = 0
    let pledgedPercentageTotal = 0

    if (oversellAvailable) {
      const oversellPercentageTotal = oversellPercentage / 100
      goalPercentageTotal = 100 - oversellPercentage
      oversellPercentagePledged =
        totalSold >= totalGoal
          ? ((totalSold - totalGoal) /
              ((totalGoal * oversellPercentageTotal) / 100)) *
            oversellPercentageTotal
          : 0

      pledgedPercentageTotal = (totalSold / totalGoal) * goalPercentageTotal
    } else {
      pledgedPercentageTotal =
        aasmState === 'fully_funded' ? 100 : (totalSold / totalGoal) * 100
    }

    return (
      <>
        <div className='relative h-3 overflow-hidden rounded-full bg-gray-200'>
          <div
            className={
              'absolute left-0 top-0 h-3 rounded-l-full bg-gradient-to-r from-orange-400 to-pink-500 to-purple-600'
            }
            style={{
              width: `${pledgedPercentageTotal}%`,
            }}
          />
          <div
            className={
              'absolute left-0 top-0 h-3 rounded-r-full bg-gradient-to-r from-green-400 to-green-500 to-green-600'
            }
            style={{
              borderLeft:
                totalSold >= totalGoal
                  ? `1px solid gray-100`
                  : `1px solid gray-400`,
              marginLeft: `${goalPercentageTotal}%`,
              width: `${oversellPercentagePledged}%`,
            }}
          />
        </div>
        {oversellAvailable && (
          <div
            style={{ paddingLeft: `calc(${goalPercentageTotal}% - 6px)` }}
            className='bottom-arrow'
          />
        )}
      </>
    )
  }

  return (
    <>
      {showInstructions && (
        <BackInstructionsModal
          showGeoRestrictionAlert={geoRestriction}
          onClose={() => {
            setShowInstructions(false)
            setSeenInstructions(true)
            backProject()
          }}
        />
      )}
      <section className='w-full bg-white'>
        <div className='mx-auto flex w-full max-w-7xl flex-col px-4 py-16'>
          <p className='mb-2 text-5xl font-medium'>
            {title}{' '}
            <span className='text-lg uppercase text-gray-400'>
              {aasmState?.replace('_', ' ')}
            </span>
          </p>
          {renderSocialIcons()}
          <div className='flex w-full flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-8'>
            <div className='flex w-full flex-col lg:w-8/12'>
              <figure className='max-h-100 mb-1 w-full overflow-hidden rounded-xl'>
                <img
                  src={coverImage}
                  className='aspect-[16/9] w-full object-cover'
                />
              </figure>
            </div>

            <div className='flex w-full flex-col rounded-lg bg-white px-8 shadow-lg lg:w-4/12'>
              <div className='flex w-full flex-col py-3'>
                <p className='flex items-center text-3xl font-medium text-blue-600'>
                  <img
                    src={`/${fundingType}.svg`}
                    className='mr-3 inline-block h-6'
                  />
                  {`${totalSold} ${fundingType.toUpperCase()}`}
                </p>
                <p className='text-md font-light text-gray-400'>
                  {`pledged of ${totalGoal.toPrecision(
                    6,
                  )} ${fundingType.toUpperCase()} goal`}
                </p>
              </div>
              {renderProgressBar()}
              <div className='flex w-full grid-cols-2 flex-row space-x-12 py-3'>
                <div className=''>
                  <p className='text-2xl font-medium text-blue-600'>
                    {renderTimer()}
                  </p>
                  <p className='text-md font-light text-gray-400'>to go</p>
                </div>
              </div>
              {showTiers && (
                <div className='flex'>
                  <Tiers
                    tiers={tiers}
                    currency={fundingType.toUpperCase()}
                    stats={ethStats || icStats}
                    oversellPercentage={oversellPercentage}
                    {...{ selectedTierState }}
                  />
                </div>
              )}
              <div className='mt-2 text-center text-xs'>{renderStatus()}</div>
              {renderFundButton()}
              {externalId == 0 && renderClaimNftButton()}
              {renderTwitterShare()}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero
