import React from 'react'
import classNames from 'classnames'
import Description from '../description'

const Rewards = ({ project }) => {
  const { rewards, rewardBlocks } = project

  const renderRewards = () => {
    if (rewardBlocks) return <Description blocks={rewardBlocks} />

    return (
      <div
        className={classNames(
          'my-2 flex flex-1 flex-col whitespace-pre-wrap text-gray-600',
        )}
        dangerouslySetInnerHTML={{ __html: rewards }}
      />
    )
  }

  return (
    <div className='flex w-full flex-col items-start rounded-2xl bg-white p-8 shadow'>
      <p className='text-2xl font-bold text-black'>Rewards</p>

      {renderRewards()}
    </div>
  )
}

export default Rewards
