import React from 'react'
import Grid from './grid'
import { gql, useQuery } from '@apollo/client'

const PROJECTS_QUERY = gql`
  query Projects(
    $states: [String!]
    $keywords: String
    $categories: [String!]
  ) {
    projects(states: $states, keywords: $keywords, categories: $categories) {
      urlSlug
      title
      listImage
      aasmState
      totalGoal
      fundingType
      user {
        firstName
        lastName
      }
    }
  }
`

export default function ProjectList({
  searchTerm,
  categories,
  header,
  statuses,
}) {
  const { data: projectData, loading } = useQuery(PROJECTS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { states: statuses, keywords: searchTerm, categories },
  })

  return (
    <section className='w-full py-5'>
      <div className='mx-auto mb-2 w-full max-w-7xl px-4 text-sm font-semibold uppercase text-gray-400'>
        {header}
      </div>
      {!loading &&
      (!projectData?.projects || projectData?.projects?.length === 0) ? (
        <div className='mx-auto w-full max-w-7xl px-4'>
          No projects in this section
        </div>
      ) : (
        <Grid
          items={projectData?.projects}
          stats={projectData?.stats}
          isLoading={loading}
        />
      )}
    </section>
  )
}
